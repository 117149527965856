import React, { useState, useEffect, useCallback, useReducer, useMemo } from 'react';

import BeatLoader from 'react-spinners/BeatLoader';
import SyncLoader from 'react-spinners/SyncLoader';

import {Timestamp} from 'firebase/firestore'

// TODO: move to a utils/ folder
function Loading(params) {
  const active = params.active;
  const color = params.color || '#ff8200';
  return (
    <div className="text-center"><BeatLoader color={color} loading={active} {...params} /></div>
  );
}
function LoadingPage(params) {
  return (
    <main style={{ padding: '1rem 0' }}>
      <Loading {...params}/>
    </main>
  );
}
function FullscreenLoadingPage(params) {
  //
  return (
    <div className="loading-page">
      {/* <div className="py-2"><img src='' alt="Elevation Fit"/></div> */}
      <div className="text-lg mb-3">Elevation Fit</div>
      <SyncLoader size="20px" margin="10px" color="#F96302"/>
    </div>
  );
}
function ErrorPage(params) {
  //
  let errorMessage = params.message || 'Unknown error. Please try again';
  return (
    <div className="error-page">
      <div>An error occurred.</div>
      <div>{errorMessage}</div>
    </div>
  );
}

const defaultLoadingState = (defaultValue) => {
  return {
    loading: defaultValue === undefined || defaultValue === null,
    value: defaultValue
  };
}
const loadingReducer = (state, action) => {
  switch (action.type) {
    case 'error':
      return {...state, error: action.error, loading: false, value: undefined};
    case 'reset':
      return defaultLoadingState(action.defaultValue);
    case 'value':
      return {...state, error: undefined, loading: false, value: action.value};
    default:
      return state;
  }
};
function useLoadingValue(defaultValueFn) {
  const defaultValue = defaultValueFn ? defaultValueFn() : undefined;
  const [state, dispatch] = useReducer(loadingReducer, defaultLoadingState(defaultValue))

  const reset = useCallback(() => {
    const defaultValue = defaultValueFn ? defaultValueFn() : undefined;
    dispatch({ type: 'reset', defaultValue });
  }, [defaultValueFn]);

  const setError = useCallback((error) => {
    dispatch({ type: 'error', error });
  }, []);

  const setValue = useCallback((value) => {
    dispatch({ type: 'value', value });
  }, []);

  return useMemo(() => {
    console.log('usememo', state);
    return {error: state.error, loading: state.loading, reset, setError, setValue, value: state.value}
  }, [state.error, state.loading, state.value, reset, setError, setValue]);
}

function transformFirestoreDates(data) {
  for (let key in data) {
    if (data[key] && data[key].__proto__ === Timestamp.prototype) {
      data[key] = data[key].toDate();
    }
  }
  return data;
}

export {
  ErrorPage,
  Loading,
  LoadingPage,
  useLoadingValue,
  FullscreenLoadingPage,
  transformFirestoreDates
};