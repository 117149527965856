import React, { useState, useEffect, useReducer, useRef } from 'react';

import { collection, doc, getDocs, getDoc, query, runTransaction, updateDoc, onSnapshot, setDoc, where, orderBy, limit, limitToLast } from 'firebase/firestore';
import { transformFirestoreDates } from '../../utils';
import { db } from '../../firebase';
import { useCollection } from 'react-firebase-hooks/firestore';
import moment from 'moment';

import { SwipeableList, SwipeableListItem } from '@sandstreamdev/react-swipeable-list';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';
import MessageListItem from './message-list-item';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/pro-regular-svg-icons';

const oneHourAgo = moment().subtract(1, 'hour').toDate();
export default function MessageGiftList({onClose}) {
    // const messagesRef = query(collection(db, 'messages'), where('timestamp', '>=', oneHourAgo), orderBy('timestamp', 'desc'));
    // const messagesRef = query(collection(db, 'messages'), where('queued', '==', true), orderBy('timestamp', 'desc'), limit(25));
    const messagesRef = query(collection(db, 'messages'), orderBy('gift_time', 'asc'), limitToLast(30));
    const [messageSnaps, loading, error] = useCollection(messagesRef);

    // TODO: timestamp filter?

    if (error) {
        console.error(error);
    }

    const messages = messageSnaps ? messageSnaps.docs.map((snap) => {
        const data = transformFirestoreDates(snap.data());
        data.$id = snap.id;
        return data;
    }).filter(msg => msg.gift) : [];

    const unqueueMsg = function(msgId) {
        const ref = doc(collection(db, 'messages'), msgId);
        return updateDoc(ref, {gift: false, gift_time: null});
    };

    // fade out?
    const handleSwipeLeft = (msg) => {
        const msgId = msg.$id;
        // console.log('swipe left', msgId);
        return unqueueMsg(msgId);
    };
    const handleSwipeRight = (msg) => {
        const msgId = msg.$id;
        // console.log('swipe right', msgId);
        return unqueueMsg(msgId);
    };

    const swipeLeftMenu = <div className="flex items-center justify-end h-full w-full px-8 bg-amber-500 text-white"><FontAwesomeIcon icon={faUndo} size="lg"/></div>;
    const swipeRightMenu = <div className="flex items-center justify-start h-full w-full px-8 bg-amber-500 text-white"><FontAwesomeIcon icon={faUndo} size="lg"/></div>;
    return (
        <div className="message-list w-full xl:w-1/2">
            <SwipeableList>
            {({ className, ...rest }) => (
                <div className={className + ' my-1'}>
                    {messages.map((msg) => (
                        <SwipeableListItem key={msg.$id} swipeLeft={{content: swipeLeftMenu, action: () => handleSwipeLeft(msg)}} swipeRight={{content: swipeRightMenu, action: () => handleSwipeRight(msg)}}>
                            <MessageListItem {...msg}/>
                        </SwipeableListItem>
                    ))}
                </div>
            )}
            </SwipeableList>
        </div>
    );
}