import logo from './logo.svg';
import './App.css';

import MessageList from './components/chat/message-list';


function App() {
  return (
    <div className="App">
      <section>
        <MessageList/>
      </section>
    </div>
  );
}

export default App;
