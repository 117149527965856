import React, { useState, useEffect, useReducer, useRef } from 'react';

import { collection, doc, getDocs, getDoc, query, runTransaction, updateDoc, onSnapshot, setDoc, where, orderBy, limit, limitToLast } from 'firebase/firestore';
import { transformFirestoreDates } from '../../utils';
import { db } from '../../firebase';
import moment from 'moment';

import '@sandstreamdev/react-swipeable-list/dist/styles.css';

const serviceSettings = {
    youtube: {logo: '/img/yt-logo-white.png'},
    facebook: {logo: '/img/fb-logo-white.png'},
}
export default function MessageListItem(props) {
    // TODO:
    // Service/platform, username, profile pic, onSwipe? on Click... reveal options.. props.handleQueue() .. handleAction? .. dispatch?
    // const liveStyle = props.live ? ' shadow-lg shadow-rose-500/50 border-rose-500' : 'border-white';
    // if (props.tally) {

    // }
    // const tallyColor = props.tally && props.tally !== 'default' ? '' : '';
    // let addedClass = 'border-rose-500';
    const styles = props.styles || {'borderColor': '#18181b'};
    // const liveStyle = props.live ? ' shadow-lg shadow-rose-500/50 border-rose-500' : 'border-white';
    const className = "px-1 pb-1 border-2";
    const profileImage = props.profile_img || '/img/user.png';
    const tally = props.tally || false;

    let messageService = serviceSettings[props.service] || serviceSettings.yt;
    let channelLabels = {
        elevationplus: 'Elevation+',
        elevationchurch: 'Elevation Church',
        stevenfurtick: 'Steven Furtick',
        hollyfurtick: 'Holly Furtick'
    };
    return (
        <div className={className} style={styles}>
            <div className="flex">
                <div className="ec-profile-pic shrink-0 rounded-lg mr-3" style={{backgroundImage: "url('" + profileImage + "')"}}>
                    {tally && <div className="dot-container"><span className="dot"></span></div>}
                </div>
                {/* <img className="object-cover w-10 h-10 mr-3 ml-1 rounded-lg" src={props.profile_img || '/img/user.png'} alt="Profile" referrerPolicy="no-referrer"/> */}
                {/* <div className="flex flex-col justify-center">
                    ...
                </div> */}
                <div className="">
                    <div className="leading-none flex">
                        <div className="mr-2 font-bold">{props.username}</div>
                        <div className="pt-0.5 opacity-60"><img className="w-4" src={messageService.logo} referrerPolicy="no-referrer"/></div>
                        <div className="text-xs ml-2 text-zinc-400">{channelLabels[props.channel] || channelLabels.elevationchurch}</div>
                    </div>
                    <div className="pt-1">{props.text}</div>
                </div>
            </div>
        </div>
    )
}
