import React, { useState, useEffect, useRef } from 'react';

import { collection, doc, query, runTransaction, updateDoc, onSnapshot, setDoc, where, orderBy, limit, limitToLast } from 'firebase/firestore';
import { transformFirestoreDates } from '../../utils';
import { db } from '../../firebase';
import { useCollection } from 'react-firebase-hooks/firestore';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGift, faHeart, faCommentAlt, faMessageMiddle, faUndo } from '@fortawesome/pro-regular-svg-icons';
import { faFilter, faGift as faGiftSolid, faMessageMiddle as faMessageMiddleSolid, faHeart as faHeartSolid, faArrowCircleDown } from '@fortawesome/pro-solid-svg-icons';

import { SwipeableList, SwipeableListItem } from '@sandstreamdev/react-swipeable-list';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';
import './message-list.css';

import MessageListItem from './message-list-item';
import MessageQueue from './message-queue';
import MessageGiftList from './message-gift-list';

function FilterMenu({filters, setFilters, onSave}) {
    //
    console.log('filtermenu', filters);
    const handleSubmit = (e) => {
        e.preventDefault();
        return onSave();
    };
    return (
        <div className="bg-white p-2">
            <form name="filterForm" onSubmit={handleSubmit}>
                <div className="flex gap-2">
                    <div className="flex flex-col w-1/2">
                        <input type="text" name="search" value={filters.search} onChange={(e) => setFilters({search: e.target.value})} className="rounded text-sm"/>
                        <div className="text-xs"><label>Search Term</label></div>
                    </div>
                    <div className="flex flex-col w-1/4">
                        <input type="number" name="minWords" value={filters.minWords} onChange={(e) => setFilters({minWords: parseInt(e.target.value)})} className="rounded text-sm"/>
                        <div className="text-xs"><label>Minimum Words</label></div>
                    </div>
                    <div className="w-1/4"><button className="btn btn-primary bg-secondary text-black p-1">Save</button></div>
                </div>
            </form>
        </div>
    )
}

const generateMessage = () => {
    const words = ["The sky", "above", "the port", "was", "the color of television", "tuned", "to", "a dead channel", ".", "All", "this happened", "more or less", ".", "I", "had", "the story", "bit by bit", "from various people", "and", "as generally", "happens", "in such cases", "each time", "it", "was", "a different story", ".", "It", "was", "a pleasure", "to", "burn"];
    const text = [];
    let x = 7;
    while (--x) text.push(words[Math.floor(Math.random() * words.length)]);
    return {text: text.join(" "), $id: Date.now(), username: 'ChatBot', timestamp: new Date(), profile_img: '/img/user.png', service: 'youtube'};
}
const calcDistanceFromBottom = function(target) {
    if (!target || !target.scrollHeight) {
        return 0;
    }
    const height = target.scrollHeight - target.offsetHeight;
    return height - Math.floor(target.scrollTop);
}

const oneHourAgo = moment().subtract(1, 'hour').toDate();
export default function MessageList() {
    //
    const [showingQueued, setShowingQueued] = useState(false);
    const [showingGift, setShowingGift] = useState(false);
    const [manualScroll, setManualScroll] = useState(false);

    const [displayedMessages, setDisplayedMessages] = useState([]);
    const [incomingMessages, setIncomingMessages] = useState([]);
    const [messagesLoading, setMessagesLoading] = useState(true);

    const chatEl = useRef(null);

    const [menuOpen, setMenuOpen] = useState(false);
    const [filters, setFilters] = useState({
        keywords: [],
        channels: [],
        minWords: 1,
        search: '',
    });

    // TODO: index?
    // const lastMessage = displayedMessages.length && displayedMessages[displayedMessages.length-1];
    useEffect(() => {
        // const messagesRef = query(collection(db, 'messages'), orderBy('timestamp', 'asc'), limitToLast(25));
        // const messagesRef = query(collection(db, 'messages'), where('queued', '!=', true), orderBy('queued'), orderBy('timestamp', 'asc'), limitToLast(25));
        let messagesRef = query(collection(db, 'messages'), where('timestamp', '>=', oneHourAgo), orderBy('timestamp', 'asc'), limitToLast(400));
        let isTest = window.location.pathname.match('test');
        if (isTest) {
            messagesRef = query(collection(db, 'messages'), orderBy('timestamp', 'desc'), limit(30));
        }

        const unsub = onSnapshot(messagesRef, (querySnapshot) => {
            let newMessages = querySnapshot.docs.map((snap) => {
                return {$id: snap.id, ...snap.data()};
            }).filter(data => !data.gift && !data.queued);
            // console.log('snapshot new messages', newMessages);
            // TODO: filter here?
            if (messagesLoading) {
                setMessagesLoading(false);
                setDisplayedMessages((prevMessages) => [...newMessages]);
            } else {
                // setIncomingMessages((prevMessages) => [...newMessages]);
                setDisplayedMessages((prevMessages) => [...newMessages]);
            }

            if (!manualScroll) {
                scrollToLive(false);
            }
        });
        return unsub;
    }, []);

    useEffect(() => {
        if (!chatEl || showingQueued || showingGift) {
            return;
        }
        const current = chatEl.current;
        let lastDistance = 999999;//calcDistanceFromBottom(current);
        let manualScrollActive = false;
        const domNodehandler = (event) => {
            const { currentTarget: target } = event;
            if (manualScrollActive) {
                return;
            }
            const distanceFromBottom = calcDistanceFromBottom(target);
            const behavior = distanceFromBottom > 700 ? 'auto' : 'smooth';
            lastDistance = distanceFromBottom;
            target.scroll({ top: target.scrollHeight, behavior});
        };
        const scrollHandler = (event) => {
            const { currentTarget: target } = event;
            const distanceFromBottom = calcDistanceFromBottom(target);
            const difference = lastDistance - distanceFromBottom;
            const scrollingUp = difference < 0;
            // console.log('on scroll distance', distanceFromBottom, lastDistance, difference, scrollingUp);
            if (!manualScrollActive && scrollingUp && distanceFromBottom > 100) {
                manualScrollActive = true;
                setManualScroll(true);
            } else if (manualScrollActive && distanceFromBottom < 100) {
                manualScrollActive = false;
                setManualScroll(false);
            }
            lastDistance = distanceFromBottom;
        };
        // current.addEventListener('DOMNodeInserted', domNodehandler);
        current.addEventListener('scroll', scrollHandler);
        scrollToLive(true);
        setManualScroll(false);
        return () => {
            // current.removeEventListener('DOMNodeInserted', domNodehandler);
            current.removeEventListener('scroll', scrollHandler);
        };
    }, [chatEl, showingQueued, showingGift]);

    // // // Test messages
    // useEffect(() => {
    //     if (showingQueued) {
    //         return;
    //     }
    //     const interval = setInterval(() => {
    //         const msg = generateMessage();
    //         // console.log('setting msg', msg);
    //         setDisplayedMessages(prevMsg => [...prevMsg, msg]);
    //         scrollToLive(false);
    //         // setIncomingMessages(prevMsg => [...prevMsg, generateMessage()]);
    //     }, 2000);
    //     return () => clearInterval(interval);
    // }, [showingQueued]);

    const scrollToLive = function(jump) {
        if (!chatEl) {
            return;
        }
        const current = chatEl.current;
        const distanceFromBottom = calcDistanceFromBottom(current);
        // const behavior = distanceFromBottom > 700 ? 'auto' : 'smooth';
        const behavior = jump ? 'auto' : 'smooth';
        current.scroll({ top: current.scrollHeight, behavior });
    };

    // TODO: keywords would need to highlight? not really a filter
    const filterHandlers = {
        keywords: ({text}, words = []) => true,
        channels: ({channel = ''}, channels = []) => true,
        minWords: ({text}, count = 1) => text.split(' ').length >= count,
        search: ({text}, q = '') => q.length > 0 ? text.match(new RegExp(q.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i')) : true,
    };
    const applyFilters = function(msg) {
        for (let key in filterHandlers) {
            let handler = filterHandlers[key];
            let filter = filters[key];
            if (!handler(msg, filter)) {
                return false;
            }
        }
        return true;
    };
    // const messages = messageSnaps ? messageSnaps.docs.map((snap) => {
    //     const data = transformFirestoreDates(snap.data());
    //     data.$id = snap.id;
    //     return data;
    // }).filter((msg) => !msg.queued && !msg.gift).filter(applyFilters).reverse() : [];

    const queueMsg = function(msgId) {
        const ref = doc(collection(db, 'messages'), msgId);
        return updateDoc(ref, {queued: true, queued_time: new Date()});
    };
    const giftMsg = function(msgId) {
        const ref = doc(collection(db, 'messages'), msgId);
        return updateDoc(ref, {gift: true, gift_time: new Date()});
    };

    // fade out?
    const handleSwipeLeft = (msg) => {
        // Queue
        const msgId = msg.$id;
        console.log('swipe left', msgId);
        return giftMsg(msgId);
    };
    const handleSwipeRight = (msg) => {
        // Gift
        const msgId = msg.$id;
        console.log('swipe right', msgId);
        return queueMsg(msgId);
    };

    const showGifts = () => {
        setShowingGift(true);
        setShowingQueued(false);
    }
    const showQueue = () => {
        setShowingGift(false);
        setShowingQueued(true);
    }
    const showChat = () => {
        setShowingGift(false);
        setShowingQueued(false);
        // scrollToLive();
    };

    // if (showingQueued) {
        // return <MessageQueue onClose={() => setShowingQueued(false)}/>;
    // }
    let showingChat = !showingGift && !showingQueued;
    let pageTitle = showingGift ? 'Follow Up' : (showingQueued ? 'Favorites' : 'Chat');

    const swipeLeftMenu = <div className="flex items-center justify-end h-full w-full px-8 bg-sky-500 text-white"><FontAwesomeIcon icon={faGift} size="lg"/></div>;
    const swipeRightMenu = <div className="flex items-center justify-start h-full w-full px-8 bg-red-500 text-white"><FontAwesomeIcon icon={faHeart} size="lg"/></div>;
    return (
        <div className="chat-container" ref={chatEl}>
            {window.location.pathname !== '/stage' ?
                <div className="header-menu backdrop-blur-xl text-white w-full xl:w-1/2 pb-2 pt-4 px-6 flex justify-between items-center">
                    <div className="font-bold">{pageTitle}</div>
                    {/* <div>{showingGift || showingQueued ? ' ' : <FontAwesomeIcon icon={faFilter} size="sm"/>}</div> */}
                    {/* <div><img src="/img/ec-plus.jpg" className="h-16 rounded-full shadow-lg shadow-rose-500/50 border-4 border-rose-500" alt="elevation plus"/></div>
                    <div><button className="btn rounded-lg bg-black text-white p-1" onClick={() => setShowingQueued(true)}>Queued</button></div> */}
                </div>
            : <div>&nbsp;</div> }
            {/* <div>
                <div className="text-center mb-2"><button className="btn btn-primary w-1/4 p-1" onClick={() => setMenuOpen(!menuOpen)}>Options</button></div>
                {menuOpen && <div><FilterMenu filters={filters} setFilters={(menuFilters) => setFilters({...filters, ...menuFilters})} onSave={() => setMenuOpen(false)}/></div>}
            </div> */}
            {showingQueued ? <MessageQueue onClose={() => showChat()}/> : showingGift ? <MessageGiftList onClose={() => showChat()}/> : <div className="message-list w-full xl:w-1/2">
                <SwipeableList>
                {({ className, ...rest }) => (
                    <div className={className}>
                        {displayedMessages.map((msg) => (
                            <SwipeableListItem key={msg.$id} swipeLeft={{content: swipeLeftMenu, action: () => handleSwipeLeft(msg)}} swipeRight={{content: swipeRightMenu, action: () => handleSwipeRight(msg)}}>
                                <MessageListItem {...msg}/>
                            </SwipeableListItem>
                        ))}
                    </div>
                )}
                </SwipeableList>
            </div>
            }
            {window.location.pathname !== '/stage' ?
                <div className="footer-menu w-full xl:w-1/2">
                    <div className="flex justify-end py-2 btn-back-to-live">{showingChat && manualScroll && <button onClick={() => scrollToLive(false)} className="py-1 px-4"><FontAwesomeIcon icon={faArrowCircleDown} size="lg"/></button>}&nbsp;</div>
                    <div className="menu-options flex items-center backdrop-blur-xl justify-around text-zinc-500 pb-4 text-center text-sm">
                        <div onClick={() => showGifts()} className={showingGift ? 'text-white' : ''}>
                            <FontAwesomeIcon icon={showingGift ? faGiftSolid : faGift} size="2xl"/>
                            <div className="text-sm pt-1">Follow Up</div>
                        </div>
                        <div onClick={() => showChat()} className={!showingQueued && !showingGift ? 'text-white' : ''}>
                            <FontAwesomeIcon icon={showingChat ? faMessageMiddleSolid : faMessageMiddle } size="2xl"/>
                            <div className="text-sm pt-1">Chat</div>
                        </div>
                        <div onClick={() => showQueue()} className={showingQueued  ? 'text-white' : ''}>
                            <FontAwesomeIcon icon={showingQueued ? faHeartSolid : faHeart } size="2xl"/>
                            <div className="text-sm pt-1">Favorites</div>
                        </div>
                    </div>
                </div>
            : null }
        </div>
    );
}