import { initializeApp } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyCwFV799m0C4GWmcXp5noSoWIefDm5RubA",
  authDomain: "ec--live-chat.firebaseapp.com",
  projectId: "ec-youtube-live-chat",
  storageBucket: "ec-youtube-live-chat.appspot.com",
  messagingSenderId: "966487285364",
  appId: "1:966487285364:web:f15a90a6cf5f59653cbb21"
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const functions = getFunctions(firebaseApp, 'us-east1');

if (window.location.hostname === 'localhost') {
  connectFunctionsEmulator(functions, 'localhost', 5001);
  // connectFirestoreEmulator(db, 'localhost', 8080);
}

export {
  db,
  functions,
  firebaseApp
};
